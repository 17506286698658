import { useState } from "react";
import ForgotPassword from "./ForgotPassword";
import PasswordOtp from "./PasswordOtp";

const PasswordReset = () => {
  const [step, setStep] = useState(1);
  const [email, setEmail] = useState("");

  return (
    <>
      {step === 1 ? (
        <ForgotPassword setStep={setStep} setResetEmail={setEmail} />
      ) : (
        <PasswordOtp email={email} />
      )}
    </>
  );
};

export default PasswordReset;
