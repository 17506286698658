import { Route, Routes, useNavigate } from "react-router-dom";
import NotFound from "./pages/NotFound";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { RootState } from "./store/redux/store";
import { ApiResponse, User } from "./types";
import ReactGA from "react-ga";
import Home from "./pages/Home";
import About from "./pages/About";
import Faq from "./pages/Faq";
import Contact from "./pages/Contact";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import Terms from "./pages/Terms";
import Library from "./pages/Library";
import Plans from "./pages/Plans";
import Search from "./pages/Search";
import Video from "./pages/Video";
import { loadUser, logoutUser, noUser } from "./store/redux/userRedux";
import { api } from "./utils/apiClient";
import Login from "./pages/auth/Login";
import Verifying from "./pages/auth/Verifying";
import Profile from "./pages/account/Profile";
import Favorites from "./pages/account/Favorites";
import Questions from "./pages/account/Questions";
import EditAccout from "./pages/account/EditAccout";
import Subscription from "./pages/account/Subscription";
import Register from "./pages/auth/Register";
import VerifyOtp from "./pages/auth/VerifyOtp";
import CreateAccount from "./pages/auth/CreateAccount";
import PasswordReset from "./pages/auth/PasswordReset";

const TRACKING_ID = process.env.REACT_APP_TRACKING_ID ?? "";
ReactGA.initialize(TRACKING_ID);

interface RouteElement {
  children: JSX.Element;
}

export const PrivateRoute = ({ children }: RouteElement) => {
  const user: User | null = useSelector(
    (state: RootState) => state.user.currentUser
  );
  const navigate = useNavigate();

  useEffect(() => {
    if (!user) {
      navigate("/login");
    }
  }, [navigate, user]);

  return user ? <>{children}</> : null;
};

export const AuthRoute = ({ children }: RouteElement) => {
  const user: User | null = useSelector(
    (state: RootState) => state.user.currentUser
  );
  const navigate = useNavigate();

  useEffect(() => {
    if (user) {
      navigate("/");
    }
  }, [navigate, user]);

  return !user ? <>{children}</> : null;
};

const App = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    const token = localStorage.getItem("tlp-access-token");

    const getUser = async () => {
      try {
        const userData = await api<ApiResponse<User>>({
          method: "GET",
          url: "/api/user",
        });

        const user = userData?.data;

        if (user?.disabled) {
          localStorage.clear();
          dispatch(logoutUser());
          return;
        }

        dispatch(loadUser(userData?.data));
      } catch (error: any) {
        if (error.response.data === "Token has expired. Please log in again.") {
          localStorage.clear();
          dispatch(logoutUser());
        }
      }
    };

    if (token) {
      getUser();
    } else {
      dispatch(noUser());
    }
  }, [dispatch]);

  return (
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/about" element={<About />} />
      <Route
        path="/account-edit"
        element={
          <PrivateRoute>
            <EditAccout />
          </PrivateRoute>
        }
      />
      <Route
        path="/account-favorites"
        element={
          <PrivateRoute>
            <Favorites />
          </PrivateRoute>
        }
      />
      <Route
        path="/account-profile"
        element={
          <PrivateRoute>
            <Profile />
          </PrivateRoute>
        }
      />
      <Route
        path="/account-questions"
        element={
          <PrivateRoute>
            <Questions />
          </PrivateRoute>
        }
      />
      <Route
        path="/account-subscription"
        element={
          <PrivateRoute>
            <Subscription />
          </PrivateRoute>
        }
      />
      <Route
        path="/auth/google"
        element={
          <AuthRoute>
            <Verifying />
          </AuthRoute>
        }
      />
      <Route path="/contact" element={<Contact />} />
      <Route
        path="/create-account"
        element={
          <AuthRoute>
            <CreateAccount />
          </AuthRoute>
        }
      />
      <Route path="/faq" element={<Faq />} />
      <Route path="/library" element={<Library />} />
      <Route
        path="/login"
        element={
          <AuthRoute>
            <Login />
          </AuthRoute>
        }
      />
      <Route path="/plans" element={<Plans />} />
      <Route path="/privacy-policy" element={<PrivacyPolicy />} />
      <Route
        path="/register"
        element={
          <AuthRoute>
            <Register />
          </AuthRoute>
        }
      />
      <Route path="/reset-password" element={<PasswordReset />} />
      <Route path="/search" element={<Search />} />
      <Route path="/terms-of-use" element={<Terms />} />
      <Route
        path="/verify-account"
        element={
          <AuthRoute>
            <VerifyOtp />
          </AuthRoute>
        }
      />
      <Route path="/video/:videoId" element={<Video />} />
      <Route path="*" element={<NotFound />} />
    </Routes>
  );
};

export default App;
