import { FormEventHandler, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import logo from "../../assets/images/tlp-logo-white.png";
import OtpInput from "react-otp-input";
import { toast } from "react-toastify";
import { api } from "../../utils/apiClient";
import { ApiResponse } from "../../types";
import { IoEyeOffOutline, IoEyeOutline } from "react-icons/io5";

const PasswordOtp = ({ email }: { email: string }) => {
  const [loading, setLoading] = useState(false);
  const [otp, setOtp] = useState("");
  const [countdown, setCountdown] = useState(30);
  const [isResendDisabled, setIsResendDisabled] = useState(true);
  const [resent, setResent] = useState(false);
  const [active, setActive] = useState("otp");

  const [password, setPassword] = useState("");
  const [passwordFieldEmpty, setPasswordFieldEmpty] = useState(false);
  const [passwordError, setPasswordError] = useState(false);
  const [passwordWeak, setPasswordWeak] = useState(false);
  const [confirmPassword, setConfirmPassword] = useState("");
  const [confirmPasswordFieldEmpty, setConfirmPasswordFieldEmpty] =
    useState(false);
  const [passwordShown, setPasswordShown] = useState(false);
  const [confirmPasswordShown, setConfirmPasswordShown] = useState(false);

  const navigate = useNavigate();

  const togglePasswordVisibility = () => {
    setPasswordShown(!passwordShown);
  };

  const toggleConfirmPasswordVisibility = () => {
    setConfirmPasswordShown(!confirmPasswordShown);
  };

  useEffect(() => {
    if (countdown > 0) {
      const timer = setInterval(() => {
        setCountdown((prevCountdown) => prevCountdown - 1);
      }, 1000);
      return () => clearInterval(timer);
    } else {
      setIsResendDisabled(false);
    }
  }, [countdown]);

  const handleResendOtp = () => {
    setCountdown(30);
    setIsResendDisabled(true);
    setResent(true);
    resendOtp();
  };

  const resendOtp = async () => {
    try {
      await api({
        method: "POST",
        url: "/api/auth/forgotpassword",
        data: { email },
      });
    } catch (error: any) {
      toast.error(error.message);
    }
  };

  const resetPassword: FormEventHandler = async (e) => {
    e.preventDefault();
    setLoading(true);

    if (!password) {
      setPasswordFieldEmpty(true);
      return;
    } else {
      setPasswordFieldEmpty(false);
    }

    if (password.length < 6) {
      setPasswordWeak(true);
      return;
    } else {
      setPasswordWeak(false);
    }

    if (!confirmPassword) {
      setConfirmPasswordFieldEmpty(true);
      return;
    } else {
      setConfirmPasswordFieldEmpty(false);
    }

    if (password !== confirmPassword) {
      setPassword("");
      setConfirmPassword("");
      setPasswordError(true);
      return;
    } else {
      setPasswordError(false);
    }

    try {
      const data = await api<ApiResponse<string>>({
        method: "POST",
        url: "/api/auth/reset-password",
        data: { email, otp, newPassword: password },
      });

      toast.success(data?.message);
      setLoading(false);
      setTimeout(() => {
        navigate("/login");
      }, 1500);
    } catch (error: any) {
      setLoading(false);
      toast.error(error.message);
    }
  };

  return active === "otp" ? (
    <div
      className="fancybox-container flq-fancybox-signin fancybox-is-open flq-fancybox-open"
      role="dialog"
      tabIndex={-1}
      id="fancybox-container-1"
      style={{ transitionDuration: "1000ms" }}
    >
      <div className="fancybox-inner">
        <div className="fancybox-stage">
          <div className="fancybox-slide fancybox-slide--html fancybox-slide--current fancybox-slide--complete">
            <div
              className="flq-fancybox-content-signin fancybox-content"
              id="flq_popup_signin"
              style={{ display: "inline-block" }}
            >
              <div className="flq-signin">
                <div className="flq-fancybox-head">
                  <div className="container-fluid">
                    <Link to="/" className="flq-fancybox-brand me-auto">
                      <img src={logo} className="flq-logo" alt="logo" />
                    </Link>
                  </div>
                </div>

                <div className="flq-fancybox-body pb-6">
                  <form
                    className="flq-signin-content"
                    onSubmit={(e) => {
                      e.preventDefault();
                      setActive("password");
                    }}
                  >
                    <h4 className="mb-2 pb-1 text-center">Forgot Password</h4>
                    <p className="mb-3">
                      Please enter the OTP we sent to{" "}
                      {email ? <strong>{email}</strong> : "your email address"}{" "}
                      to proceed.
                    </p>
                    <div className="row justify-content-between gy-4">
                      <OtpInput
                        value={otp}
                        onChange={setOtp}
                        numInputs={6}
                        renderInput={(props) => <input {...props} />}
                        containerStyle={{
                          width: "100%",
                          padding: "0",
                          justifyContent: "center",
                        }}
                        inputStyle={{
                          width: "auto",
                          maxWidth: "40px",
                          margin: "0 5px",
                          fontSize: "20px",
                          borderRadius: "8px",
                          border: "1px solid #ffffff26",
                          outline: "none",
                          textAlign: "center",
                          backgroundColor: "#ffffff0f",
                          transition: "border-color 0.2s",
                          color: "#bfbfbf",
                        }}
                      />

                      {!resent && email && (
                        <>
                          {isResendDisabled ? (
                            <div className="col-auto">
                              <p className="flq-color-meta flq-color-title-hover">
                                Didn't receive code? Resend in {countdown}{" "}
                                seconds
                              </p>
                            </div>
                          ) : (
                            <a href="#/" onClick={handleResendOtp}>
                              Resend code
                            </a>
                          )}
                        </>
                      )}

                      {otp.length === 6 && (
                        <div className="col-12">
                          {loading ? (
                            <button
                              className="btn btn-block"
                              style={{ background: "#c79200" }}
                              disabled
                            >
                              Confirm
                            </button>
                          ) : (
                            <button
                              className="btn btn-block"
                              style={{ background: "#c79200" }}
                            >
                              Confirm
                            </button>
                          )}
                        </div>
                      )}
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  ) : (
    <div
      className="fancybox-container flq-fancybox-signin fancybox-is-open flq-fancybox-open"
      role="dialog"
      tabIndex={-1}
      id="fancybox-container-1"
      style={{ transitionDuration: "1000ms" }}
    >
      <div className="fancybox-inner">
        <div className="fancybox-stage">
          <div className="fancybox-slide fancybox-slide--html fancybox-slide--current fancybox-slide--complete">
            <div
              className="flq-fancybox-content-signin fancybox-content"
              id="flq_popup_signin"
              style={{ display: "inline-block" }}
            >
              <div className="flq-signin">
                <div className="flq-fancybox-head">
                  <div className="container-fluid">
                    <Link to="/" className="flq-fancybox-brand me-auto">
                      <img src={logo} className="flq-logo" alt="logo" />
                    </Link>
                  </div>
                </div>
                <div className="flq-fancybox-body pb-6">
                  <form onSubmit={resetPassword} className="flq-signin-content">
                    <h4 className="mb-4 pb-1 text-center">Reset Password</h4>
                    <div className="row justify-content-between gy-4">
                      <div className="col-12" style={{ position: "relative" }}>
                        <input
                          className="form-control flq-form-lock flq-form-translucent"
                          type={`${passwordShown ? "text" : "password"}`}
                          placeholder="New Password"
                          value={password}
                          onChange={(e) => {
                            setPassword(e.target.value);
                            setPasswordFieldEmpty(false);
                            setPasswordError(false);
                            setPasswordWeak(false);
                          }}
                        />
                        <a
                          href="#/"
                          onClick={togglePasswordVisibility}
                          style={{
                            position: "absolute",
                            right: "32px",
                            top: "10px",
                          }}
                        >
                          {!passwordShown ? (
                            <IoEyeOutline />
                          ) : (
                            <IoEyeOffOutline />
                          )}
                        </a>
                        {passwordFieldEmpty && (
                          <p
                            className="text-danger mt-1 text-center"
                            style={{ fontSize: "15px" }}
                          >
                            Please enter a password
                          </p>
                        )}
                        {passwordWeak && (
                          <p
                            className="text-danger mt-1 text-center"
                            style={{ fontSize: "15px" }}
                          >
                            Password should be at least 6 characters long
                          </p>
                        )}
                      </div>
                      <div className="col-12" style={{ position: "relative" }}>
                        <input
                          className="form-control flq-form-lock flq-form-translucent"
                          type={`${confirmPasswordShown ? "text" : "password"}`}
                          placeholder="Confirm Password"
                          value={confirmPassword}
                          onChange={(e) => {
                            setConfirmPassword(e.target.value);
                            setConfirmPasswordFieldEmpty(false);
                            setPasswordError(false);
                            setPasswordWeak(false);
                          }}
                        />
                        <a
                          href="#/"
                          onClick={toggleConfirmPasswordVisibility}
                          style={{
                            position: "absolute",
                            right: "32px",
                            top: "10px",
                          }}
                        >
                          {!confirmPasswordShown ? (
                            <IoEyeOutline />
                          ) : (
                            <IoEyeOffOutline />
                          )}
                        </a>
                        {confirmPasswordFieldEmpty && (
                          <p
                            className="text-danger mt-1 text-center"
                            style={{ fontSize: "15px" }}
                          >
                            Please enter your password again
                          </p>
                        )}
                        {passwordError && (
                          <p
                            className="text-danger mt-1 text-center"
                            style={{ fontSize: "15px" }}
                          >
                            Passwords do not match
                          </p>
                        )}
                      </div>
                      <div className="col-12">
                        {loading ? (
                          <button
                            className="btn btn-block"
                            style={{ background: "#c79200" }}
                            disabled
                          >
                            Reset
                          </button>
                        ) : (
                          <button
                            className="btn btn-block"
                            style={{ background: "#c79200" }}
                          >
                            Reset
                          </button>
                        )}
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PasswordOtp;
