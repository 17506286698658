import { Link } from "react-router-dom";
import logo from "../../assets/images/tlp-logo-white.png";
import { FormEventHandler, useState } from "react";
import { toast } from "react-toastify";
import { api } from "../../utils/apiClient";

interface ForgotPasswordProps {
  setStep: (step: number) => void;
  setResetEmail: (email: string) => void;
}

const ForgotPassword = ({ setStep, setResetEmail }: ForgotPasswordProps) => {
  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState(false);
  const [loading, setLoading] = useState(false);

  const forgotPasswordHandler: FormEventHandler = async (e) => {
    e.preventDefault();

    const emailFormat =
      /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;

    if (!email || !email.match(emailFormat)) {
      setEmailError(true);
      return;
    } else {
      setEmailError(false);
    }

    setLoading(true);

    try {
      await api({
        method: "POST",
        url: "/api/auth/forgotpassword",
        data: { email: email.toLowerCase() },
      });

      setLoading(false);
      setResetEmail(email);
      setStep(2);
    } catch (error: any) {
      setLoading(false);
      toast.error(error.message);
    }
  };

  return (
    <div
      className="fancybox-container flq-fancybox-signin fancybox-is-open flq-fancybox-open"
      role="dialog"
      tabIndex={-1}
      id="fancybox-container-1"
      style={{ transitionDuration: "1000ms" }}
    >
      <div className="fancybox-inner">
        <div className="fancybox-stage">
          <div className="fancybox-slide fancybox-slide--html fancybox-slide--current fancybox-slide--complete">
            <div
              className="flq-fancybox-content-signin fancybox-content"
              id="flq_popup_signin"
              style={{ display: "inline-block" }}
            >
              <div className="flq-signin">
                <div className="flq-fancybox-head">
                  <div className="container-fluid">
                    <Link to="/" className="flq-fancybox-brand me-auto">
                      <img src={logo} className="flq-logo" alt="logo" />
                    </Link>
                  </div>
                </div>
                <div className="flq-fancybox-body pb-6">
                  <form
                    onSubmit={forgotPasswordHandler}
                    className="flq-signin-content"
                  >
                    <h4 className="mb-2 pb-1 text-center">Forgot Password</h4>
                    <p className="mb-3">
                      Enter your email address and we'll send you an OTP to
                      proceed.
                    </p>
                    <div className="row justify-content-between gy-4">
                      <div className="col-12">
                        <input
                          className="form-control flq-form-mail flq-form-translucent"
                          type="email"
                          name="email"
                          placeholder="Email address"
                          value={email}
                          onChange={(e) => {
                            setEmail(e.target.value);
                            setEmailError(false);
                          }}
                        />
                        {emailError && (
                          <p
                            className="text-danger mt-1 text-center"
                            style={{ fontSize: "15px" }}
                          >
                            Please enter a valid email address
                          </p>
                        )}
                      </div>

                      <div className="col-12">
                        {loading ? (
                          <button
                            className="btn btn-block"
                            style={{ background: "#c79200" }}
                            disabled
                          >
                            Send Email
                          </button>
                        ) : (
                          <button
                            className="btn btn-block"
                            style={{ background: "#c79200" }}
                          >
                            Send Email
                          </button>
                        )}
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ForgotPassword;
